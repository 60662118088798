<template>
  <div class="app" :class="`${openedleft ? 'opened-left-panel' : ''}${openedcontests ? ' opened-contests-panel' : ''}`">

    <Head :flagShow="openedleft" :isMobile="isMobileFlag" @menuClick="menuClick" />
    <div class="wrapper">
      <div class="wrapper__inner" :class="{ ranl_wrap_bg: activeType == 0 }">
        <div class="rank_container">

          <div class="ranl_wrap">
            <div class="r_tab_wrap">
              <div @click="activeType = index" v-for="(item, index) in pageType" :key="index" class="r_tab_list"
                :class="{ active: activeType == index }">
                {{ $t(item) }}</div>
            </div>

            <div v-if="activeType == 0">
              <div class="date_type_wrap">
                <div v-for="(item, index) in dateOptions" :key="index" @click="selectRankType(index)"
                  class="date_type_item" :class="{ active: index == dateType }">{{ $t(item) }}</div>
              </div>

              <div class="jackpot_container">
                <div class="jackpot_title">
                  <img src="@/assets/images/jackpot_t.png" alt="">
                </div>
                <img class="isPcImg" src="@/assets/images/rank_jackpot_b_pc.png" alt="">
                <img class="isMbImg" src="@/assets/images/rank_jackpot_b_mb.png" alt="">
                <div class="jackpot_content">
                  <span>{{ $helper.get("rule").currency.symbol }}</span>
                  144.090.584.00
                </div>
              </div>

              <div class="rank_tips">
                <img src="@/assets/images/icon_tips.png" alt="">
                <span>{{ $t('占总投注额的') }}1.4%。{{ $t('所有AAGame玩家') }}。</span>
              </div>

              <div class="rank_wrapper">
                <button @click="openHistoryPop" class="history_btn">{{ $t('历史') }}</button>
                <div class="top_three_wrap">
                  <div class="r_item translateY">
                    <div class="avatar_frame">
                      <img src="@/assets/images/runner-up_b.png" alt="">
                      <img class="head_img" :src="topThreeList[1]?.head_img" alt="">
                    </div>
                    <span class="txt_bg">{{ topThreeList[1]?.username }}</span>
                  </div>

                  <div class="r_item">
                    <div class="avatar_frame" style="margin-bottom: 10px;">
                      <img src="@/assets/images/champion_b.png" alt="">
                      <img class="head_img_f" :src="topThreeList[0]?.head_img" alt="">
                    </div>
                    <span class="txt_bg">{{ topThreeList[0]?.username }}</span>
                  </div>
                  <div class="r_item translateY">
                    <div class="avatar_frame">
                      <img src="@/assets/images/bronze_b.png" alt="">
                      <img class="head_img" :src="topThreeList[2]?.head_img" alt="">
                    </div>
                    <span class="txt_bg">{{ topThreeList[2]?.username }}</span>
                  </div>
                </div>

                <div class="rank_podium">
                  <img class="isPcImg" src="@/assets/images/rank_podium_pc.png" alt="">
                  <img class="isMbImg" src="@/assets/images/rank_podium_mb.png" alt="">
                  <div class="podium_data">
                    <div class="podium_data_item">
                      <div>{{ $t('奖励') }}:{{ topThreeList[1]?.proportion }}%</div>
                      <div>{{ $t('下注') }}:{{ topThreeList[1]?.total_price }}</div>
                    </div>
                    <div class="podium_data_item">
                      <div>{{ $t('奖励') }}:{{ topThreeList[0]?.proportion }}%</div>
                      <div>{{ $t('下注') }}:{{ topThreeList[0]?.total_price }}</div>
                    </div>
                    <div class="podium_data_item">
                      <div>{{ $t('奖励') }}:{{ topThreeList[2]?.proportion }}%</div>
                      <div>{{ $t('下注') }}:{{ topThreeList[2]?.total_price }}</div>
                    </div>
                  </div>

                  <div class="date_option_wrap">
                    <div v-for="(item, index) in dateOptions" :key="index" @click="selectRankType(index)"
                      class="date_option_item" :class="{ active: index == dateType }">{{ $t(item) }}</div>
                  </div>

                  <div class="residue_rank_wrap">
                    <div v-if="issue == 0" class="residue_rank_content" ref="residueRank" @scroll="handleScroll">

                      <div class="spacer" :style="{ height: totalHeight + 'px' }"></div>
                      <div class="residue_rank_list" :style="{ transform: `translateY(${scrollOffset}px)` }">
                        <div v-for="(item, index) in visibleItems" :key="index" class="rank_item">
                          <span class="rank_index">
                            {{ item.ranking }}
                          </span>

                          <div class="user_avatar">
                            <img :src="item.head_img" alt="">
                          </div>
                          <div class="ranking_info">
                            <div class="list_txt">
                              <span class="list_title">{{ item.username }}</span>
                              <span>{{ $t('奖励') }} {{ item.proportion }}%</span>
                            </div>

                            <div class="list_txt">
                              <span class="list_title">{{ $t('总下注') }}</span>
                              <span>{{ item.total_price }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="my_rank_wrap">
                    <div class="my_rank">{{ myBets.ranking == 0 ? $t('未上榜') : myBets.ranking }}</div>

                    <div class="my_rank_info">
                      <div class="list_txt ">
                        <div class="my_rank_font">
                          <span>{{ $t('我的下注') }}</span>
                          <span class="light_font"> {{ myBets.total_price }}</span>
                        </div>
                        <div class="my_rank_font">
                          <span>{{ $t('我的奖励') }}</span>
                          <span class="light_font"> {{ myBets.proportion }}%</span>
                        </div>
                      </div>

                      <div class="list_txt my_rank_font">
                        <span>{{ $t('距离上一名') }}</span>
                        <span class="light_font">{{ myBets.previous }}</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div v-if="activeType == 1" class="rule_page">
              <div v-html="rankRule"></div>
            </div>

            <div v-if="activeType == 2" class="rule_page">
              <div class="empty_state">
                <img src="@/assets/images/empty_img.png" alt="">
                <span>{{ $t('您还没有收到任何排名奖励，所以去投注并参与活动吧.') }}</span>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>

    <div v-if="historyPopShow" @click.self="closeHistoryPop" class="history_pop">
      <div class="history_wrapper">
        <h4 class="pop_title">{{ $t('历史') }}</h4>
        <div @click="closeHistoryPop" class="pop_close_btn"></div>

        <div class="jackpot_container">
          <div class="jackpot_title">
            <img src="@/assets/images/jackpot_t.png" alt="">
          </div>
          <img class="isPcImg" src="@/assets/images/rank_jackpot_b_pc.png" alt="">
          <img class="isMbImg" src="@/assets/images/rank_jackpot_b_mb.png" alt="">
          <div class="jackpot_content">
            <span>{{ $helper.get("rule").currency.symbol }}</span>
            144.090.584.00
          </div>
        </div>

        <div class="h_date_type_wrap">

          <div v-for="(item, index) in historyDateOption" :key="index" @click="selectRankType(index)"
            class="h_date_type_item" :class="{ active: index == dateType }">{{ $t(item) }}</div>

        </div>

        <div class="history_rank_wrap">
          <div class="history_rank_head">
            <span>{{ $t('排名') }}</span>
            <span>{{ $t('用户名') }}</span>
            <span>{{ $t('总下注') }}</span>
            <span>{{ $t('奖励') }}</span>
          </div>

          <div v-if="issue == 1" class="history_rank_body" ref="residueRank" @scroll="handleScroll">
            <div class="spacer" :style="{ height: totalHeight + 'px' }"></div>
            <div class="residue_rank_list" :style="{ transform: `translateY(${scrollOffset}px)` }">
              <div v-for="(item, index) in visibleItems" :key="index" class="history_rank_item">
                <span v-if="item.ranking == 1"><img src="@/assets/images/medal_Gold.png" alt=""></span>
                <span v-else-if="item.ranking == 2"><img src="@/assets/images/medal_Silver.png" alt=""></span>
                <span v-else-if="item.ranking == 3"><img src="@/assets/images/medal_Bronze.png" alt=""></span>
                <span v-else>{{item.ranking }}</span>
                <span>{{item.username}}</span>
                <span> {{item.total_price}}</span>
                <span class="amount_color"> {{(item.price *item.proportion)/100}}</span>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>

    <asidebar :flagShow="openedleft" @menuClick="menuClick"></asidebar>
    <tabbar @menuClick="menuClick" @contestsShow="contestsShow"></tabbar>
    <div class="app-overlay" v-if="isMobileFlag" :class="openedleft ? 'visible' : ''" @click="menuClick"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Foot from "@/components/Foot.vue";
import asidebar from "@/components/aside.vue";
import svgIcon from "@/components/svg.vue";
import tabbar from "@/components/tabbar.vue";

import { jackpot_list,jackpot_rule_detail, jackpot_rewards } from "@/api/user";
export default {
  name: "Home",
  components: {
    Head,
    Foot,
    asidebar,
    svgIcon,
    tabbar
  },
  data() {
    return {
      dateType: 0,
      dateOptions: ['日榜', '周榜', '月榜'],
      historyDateOption: ['昨日', '上周', '上月'],
      pageType: ['排行榜', '规则', '我的奖励'],
      activeType: 0,
      historyPopShow: false,
      rankListData: [],
      topThreeList: [],
      visibleItems: [],    // 当前可见的数据
      itemHeight: 68,      // 默认高度为 68
      visibleCount: 0,     // 可视区域内显示的元素个数
      startIndex: 0,       // 当前开始渲染的索引
      scrollOffset: 0,     // 滚动偏移量，用于控制虚拟列表的位置
      totalHeight: 0,      // 列表的总高度
      issue: 0,
      myBets: {
        ranking: 0,
        total_price: 0,
        proportion: '0',
        previous: 0,
        price: 0
      },
      rankRule:''

    };
  },
  methods: {
    async openHistoryPop() {
      this.historyPopShow = true
      this.issue = 1
      this.dateType = 0
      this.fetchRankList()
    },
    closeHistoryPop() {
      this.historyPopShow = false
      this.issue = 0
      this.dateType = 0
      this.fetchRankList()
    },
    menuClick() {
      this.openedleft = !this.openedleft;
    },
    contestsShow() {
      this.openedcontests = true;
    },

    async fetchRankList() {
      const params = {
        type: this.dateType + 1,
        page: 1,
        limit: 99999,
        issue: this.issue
      }
      const response = await jackpot_list(params)
      if (response.data.code == 1) {
        if (this.issue == 0) {
          this.topThreeList = response.data.data.slice(0, 3)
          this.rankListData = response.data.data.slice(3)
          this.myBets = response.data.my_bet
        } else {
          this.rankListData = response.data.data
        }
        this.updateItemHeight();
        const containerHeight = this.$el.clientHeight;
        this.visibleCount = Math.ceil(containerHeight / this.itemHeight);
        this.updateVisibleItems();
        this.$refs.residueRank.scrollTop = 0
      }
    },

    updateItemHeight() {
      if (window.innerWidth < 768) {
        this.itemHeight = 56;
      } else {
        this.itemHeight = 68;
      }
      // 更新总高度并重新计算
      this.totalHeight = this.rankListData.length * this.itemHeight;
      this.handleScroll({ target: this.$el });
      this.$refs.residueRank.scrollTop = 0
    },

    handleScroll(event) {
      const scrollTop = event.target.scrollTop;
      // 计算新的开始索引
      const newStartIndex = Math.floor(scrollTop / this.itemHeight);
      this.startIndex = newStartIndex;
      // 计算滚动偏移量
      this.scrollOffset = scrollTop - (scrollTop % this.itemHeight);
      // 更新可见的数据项
      this.updateVisibleItems();
    },
    updateVisibleItems() {
      this.visibleItems = this.rankListData.slice(this.startIndex, this.startIndex + this.visibleCount);
    },

    selectRankType(key) {
      this.dateType = key
      this.fetchRankList()

    },

    async fetchRule(){
      const respone = await jackpot_rule_detail()
      if(respone.data.code == 1){
        this.rankRule = respone.data.data
      }
    }

  },
  mounted() {
    this.fetchRankList()
    this.fetchRule()
    window.addEventListener('resize', this.updateItemHeight);
  },
  beforeDestroy() {
    // 清除监听事件
    window.removeEventListener('resize', this.updateItemHeight);
  },
  created() {
  }
};
</script>
<style lang="less" scoped>
.wrapper__inner {}

.ranl_wrap_bg {
  background: url('../../assets/images/rank_bg_pc.png');
  background-position: center;
  background-size: cover;

  @media screen and (max-width:768px) {
    background: url('../../assets/images/rank_bg_mb.png');
    background-position: center;
    background-size: cover;
  }
}

.rank_container {
  width: 100%;
  height: 100%;
  padding: 0 20px;

  @media screen and (max-width:768px) {
    width: 100%;
    padding: 0 12px;
  }
}

.ranl_wrap {
  position: relative;
  width: 85%;
  margin: 0 auto;

  @media screen and (max-width:768px) {
    width: 100%;
  }
}

.r_tab_wrap {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  @media screen and (max-width:768px) {
    width: 93%;
    margin-bottom: 10px;
  }
}

.r_tab_list {
  width: 210px;
  font-weight: 500;
  font-size: 22px;
  color: #FFCC76;
  line-height: 32px;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;

  @media screen and (max-width:1024px) {
    width: 160px;
    font-size: 18px;
    line-height: 28px;
    padding: 8px 0;
  }

  @media screen and (max-width:768px) {
    flex: 1;
    width: 100%;
    font-size: 14px;
    line-height: 26px;
    padding: 6px 0;
  }

  &:hover {
    color: #fff;
  }
}

.r_tab_list.active {
  background: linear-gradient(180deg, #FFD074 0%, #F62CC5 100%);
  border-radius: 10px;
  color: #fff;
}

.date_type_wrap {
  width: 82%;
  margin: 0 auto 24px;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media screen and (max-width:768px) {
    display: flex;
  }
}

.date_type_item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 34px;
  background: url('../../assets/images/date_card.png') no-repeat;
  background-size: cover;
  color: #FFCC76;
}

.date_type_item.active {
  background: url('../../assets/images/date_card_ac.png') no-repeat;
  background-size: cover;
  color: #F747B7;
}

.jackpot_container {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 20px;

  @media screen and (max-width:768px) {
    padding: 0;
  }
}

.jackpot_title {
  position: absolute;
  width: 50%;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;

  @media screen and (max-width:768px) {
    width: 56%;
  }
}

.jackpot_content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
  font-weight: bold;
  font-size: 42px;
  color: #FFFFFF;
  line-height: 60px;
  letter-spacing: 2px;
  text-shadow: 0px 0px 9px #FF9200;
  text-align: right;
  font-style: normal;
  padding-top: 10px;
  gap: 4px;
  z-index: 1;

  @media screen and (max-width:768px) {
    width: 100%;
    font-size: 28px;
  }
}

.isPcImg {
  display: inline-block;

  @media screen and (max-width:768px) {
    display: none;
  }
}

.isMbImg {
  display: none;

  @media screen and (max-width:768px) {
    display: inline-block;
  }
}

.rank_tips {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #FFCC76;
  gap: 8px;

  @media screen and (max-width:768px) {
    font-size: 12px;
  }


  img {
    width: 18px;
    height: 18px;

    @media screen and (max-width:768px) {
      width: 14px;
      height: 14px;
    }
  }
}

.rank_wrapper {
  position: relative;
}

.top_three_wrap {
  position: relative;
  width: 80%;
  max-width: 760px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 3%;
  z-index: 1;

  @media screen and (max-width:768px) {
    width: 95%;
    gap: 3%;
  }
}

.translateY {
  transform: translateY(24px);

  @media screen and (max-width:1024px) {
    transform: translateY(14px);
  }

  @media screen and (max-width:768px) {
    transform: translateY(16px);
  }
}

.r_item {
  text-align: center;
  font-size: 18px;
  width: 28%;

  &:first-child {
    text-align: left;
  }

  &:nth-child(2) {
    width: 38%;
  }

  &:last-child {
    text-align: right;
  }

  @media screen and (max-width:768px) {
    font-size: 12px;
    text-align: center !important;
  }
}

.avatar_frame {
  position: relative;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width:768px) {
    margin-bottom: 14px;
  }

  .head_img {
    position: absolute;
    width: 40%;
    max-width: 68px;
    max-height: 84px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -32%);
    z-index: -1;
  }

  .head_img_f {
    position: absolute;
    width: 40%;
    max-width: 84px;
    max-height: 84px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -38%);
    z-index: -1;
  }
}

.txt_bg {
  background: rgba(0, 0, 0, .4);
  border-radius: 18px;
  padding: 6px 22px;

  @media screen and (max-width:768px) {
    padding: 4px 14px;
  }
}



.rank_podium {
  position: relative;
  width: fit-content;
  max-width: 957px;
  margin: 0 auto;
  text-align: center;
  transform: translateY(-1%);

  @media screen and (max-width:768px) {
    transform: none;
  }
}

.podium_data {
  position: absolute;
  width: 100%;
  top: 8%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width:500px) {
    top: 6%;
  }
}

.podium_data_item {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #FFCC76;

  &:nth-child(2) {
    transform: translateY(-6px);
  }

  @media screen and (max-width:900px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (max-width:768px) {
    font-size: 12px;
    line-height: 20px;
  }

  @media screen and (max-width:500px) {
    font-size: 10px;
    line-height: 20px;
  }
}

.history_btn {
  position: absolute;
  top: -8px;
  right: 0;
  background: linear-gradient(180deg, #FFD074 0%, #F62CC5 100%);
  border-radius: 8px;
  padding: 8px 20px;
  font-size: 16px;
  color: #FFFFFF;
  z-index: 10;

  @media screen and (max-width:768px) {
    padding: 6px 16px;
    font-size: 14px;
    top: -12px;
    left: -12px;
    right: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.residue_rank_wrap {
  position: relative;
  width: 96%;
  margin: 0 auto;
  background: #0D131C;
  border-radius: 9px;
  padding: 12px 4px;
  transform: translateY(-90px);
  z-index: 10;

  @media screen and (max-width:1024px) {
    transform: translateY(-76px);
  }

  @media screen and (max-width:900px) {
    transform: translateY(-60px);
  }

  @media screen and (max-width:768px) {
    transform: translateY(-56px);
    width: calc(100% + 24px);
    margin: 0 -12px;
    padding: 14px 16px 0;
    gap: 6px;
    padding-bottom: 24px;
  }
}

.date_option_wrap {
  position: absolute;
  top: 25%;
  left: -86px;
  width: 120px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;

  @media screen and (max-width:768px) {
    display: none;
  }
}

.date_option_item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 38px;
  background: url('../../assets/images/date_b_mb.png') no-repeat;
  background-size: cover;
  color: #FFCC76;
  cursor: pointer;

  &:hover {
    transform: translateX(-10px);
  }
}

.date_option_item.active {
  background: url('../../assets/images/date_b_mb_ac.png') no-repeat;
  background-size: cover;
  color: #F747B7;
}



.residue_rank_content {
  position: relative;
  width: 100%;

  max-height: 250px;
  overflow-y: auto;
}

.residue_rank_list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 8px;

}

.rank_item {
  width: 49%;
  background: #1C2532;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px 12px;

  @media screen and (max-width:768px) {
    width: 100%;
    padding: 6px 10px;
  }
}

.rank_index {
  width: 56px;
  font-weight: 500;
  font-size: 16px;
  color: #90A8CF;

  @media screen and (max-width:768px) {
    width: 36px;
    font-size: 12px;
  }
}

.ranking_info {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user_avatar {
  width: 48px !important;
  height: 48px !important;
  background-color: #26354A;
  border-radius: 50%;
  margin: 0 12px;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width:768px) {
    width: 36px !important;
    height: 36px !important;
    margin: 0 6px;
  }
}

.list_txt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 22px;

  &:last-child {
    align-items: flex-end;
  }

  @media screen and (max-width:768px) {
    font-size: 12px;
  }
}

.list_title {
  color: #90A8CF;
}

.my_rank_wrap {
  width: 96%;
  margin: 0 auto;
  padding: 14px 24px;
  background: linear-gradient(180deg, #C56422 0%, #6E3513 100%);
  border-radius: 7px;
  transform: translateY(-85px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width:1024px) {
    transform: translateY(-70px);
  }

  @media screen and (max-width:900px) {
    transform: translateY(-55px);
  }

  @media screen and (max-width:768px) {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: calc(70px + env(safe-area-inset-bottom));
    transform: translateY(0);
    padding: 20px 14px;
    z-index: 99;
  }
}

.my_rank {
  width: 200px;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 24px;

  @media screen and (max-width:768px) {
    font-size: 14px;
    line-height: 18px;
    width: 80px;
  }
}

.my_rank_info {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my_rank_font {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 20px;

  @media screen and (max-width:768px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.light_font {
  font-weight: 500;
  font-size: 26px;
  color: #F8D94C;
  line-height: 28px;

  @media screen and (max-width:768px) {
    font-size: 18px;
    line-height: 20px;
  }
}




.history_pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .8);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.history_wrapper {
  position: relative;
  width: 100%;
  max-width: 900px;
  padding: 16px 24px 24px;
  background: #0D131C;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.93);
  border-radius: 9px;

  @media screen and (max-width:768px) {
    padding: 12px 15px 15px;
  }
}

.pop_title {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
  margin-bottom: 30px;
}

.pop_close_btn {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 14px;
  right: 14px;
  cursor: pointer;
  background: url('../../assets/images/close_btn.png') no-repeat;
  background-size: cover;
}

.h_date_type_wrap {
  width: 82%;
  margin: 12px auto 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width:768px) {
    display: flex;
    width: 90%;
    justify-content: space-around;

  }
}

.h_date_type_item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 126px;
  height: 48px;
  background: url('../../assets/images/date_card.png') no-repeat;
  background-size: cover;
  color: #FFCC76;
  cursor: pointer;

  @media screen and (max-width:768px) {
    width: 90px;
    height: 34px;
  }

  &:hover {
    color: #F747B7;
  }

}

.h_date_type_item.active {
  background: url('../../assets/images/date_card_ac.png') no-repeat;
  background-size: cover;
  color: #F747B7;
}

.history_rank_wrap {
  width: 100%;
  background: #161F2C;
  border-radius: 6px;
}

.history_rank_head {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 0;
  background-color: #26354A;

  @media screen and (max-width:768px) {
    padding: 12px 0;
  }

  span {
    display: block;
    flex: 1;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #BFDBFF;
    line-height: 28px;

    @media screen and (max-width:768px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.history_rank_body {
  position: relative;
  max-height: 300px;
  overflow-y: auto;
}

.history_rank_item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 0;
  border-bottom: 1px solid #2C3645;

  &:first-child {
    background: linear-gradient(90deg, #8C5F00 0%, #161F2C 60%);
  }

  &:nth-child(2) {
    background: linear-gradient(90deg, #3A5367 0%, #161F2C 60%);
  }

  &:nth-child(3) {
    background: linear-gradient(90deg, #442D0B 0%, #161F2C 60%);
  }

  span {
    display: block;
    flex: 1;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #BFDBFF;
    line-height: 32px;

    img {
      width: auto;
      height: 30px;
    }

    @media screen and (max-width:768px) {
      font-size: 14px;
      line-height: 32px;
    }
  }
}

.amount_color {
  color: #FFCC76 !important;
}


.rule_page {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.rule_awrad_t {
  margin-top: 20px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 28px;
  font-weight: 500;

  @media screen and (max-width:768px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.empty_state {
  width: 60%;
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 54px;
  text-align: center;
}
</style>
